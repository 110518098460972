/* SWAL */
.swal2-container.swal2-center>.swal2-popup {
    grid-column: 2;
    grid-row: 2;
    align-self: center;
    justify-self: center;
    border-radius: 24px;
  }
  
  .swal2-title {
    color: #000 !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 24px !important;
  }
  
  .swal2-html-container {
    font-size: 14px !important;
    line-height: 16px !important;
  }
  
  .swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    /* background-color: #FFB600 !important; */
    color: #fff;
    font-size: 1em;
    border-radius: 10px !important;
    width: 130.5px !important;
  }
  
  .swal2-styled.swal2-cancel {
    border: 1px solid !important;
    border-radius: 0.25em;
    /* background: initial; */
    background-color: #ffffff !important;
    color: #FFB600 !important;
    font-size: 1em;
    border-radius: 10px !important;
    width: 130.5px !important;
  }
  
  .swal2-styled.swal2-cancel:hover {
    background-image: linear-gradient(rgb(217 194 7 / 10%),rgb(255 255 255 / 10%)) !important;
  }
  
  .swal2-styled.swal2-default-outline:focus {
    box-shadow: 0 0 0 0 !important;
  }
  
  .swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 0 !important;
  }
  
  .swal2-styled:focus {
    outline: 0 !important;
  }
  
  .swal2-icon.swal2-success .swal2-success-ring {
    background-color: #beffd1 !important;
    border: 0.25em solid #beffd1 !important;
  }
  
  .swal2-icon.swal2-success [class^=swal2-success-line] {
    display: block;
    position: absolute;
    z-index: 2;
    height: 0.3125em;
    border-radius: 0.125em;
    background-color: #3DDB6C !important;
  }
  
  .swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
    top: -0.4375em;
    left: -2.0635em;
    transform: rotate(-45deg);
    transform-origin: 3.75em 3.75em;
    border-radius: 7.5em 0 0 7.5em;
  }
  
  .swal2-icon.swal2-success [class^=swal2-success-circular-line] {
    position: absolute;
    width: 3.75em;
    height: 7.5em;
  }
  
  .swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
    animation: swal2-animate-success-line-tip .75s;
  }
  
  .swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
    -webkit-animation: swal2-animate-success-line-long .75s;
    animation: swal2-animate-success-line-long .75s;
  }
  
  .swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
    top: 2.875em;
    left: 0.8125em;
    width: 1.5625em;
    transform: rotate(45deg);
    background-color: #3DDB6C !important;
    z-index: 99999;
  }
  
  .swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
    transform: rotate(-45deg);
    background-color: #3DDB6C !important;
    z-index: 99999;
  }
  
  .swal2-icon.swal2-success .swal2-success-ring {
    position: absolute;
    z-index: 2;
    top: -0.25em;
    left: -0.25em;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* background-color: #beffd1 !important; */
  }
  
  .swal2-icon.swal2-success .swal2-success-fix {
    position: absolute;
    z-index: 1;
    top: 0.5em;
    left: 1.625em;
    width: 0.4375em;
    height: 5.625em;
    transform: rotate(-45deg);
  }
  
  .swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
    animation: swal2-rotate-success-circular-line 4.25s ease-in;
  }
  
  .swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
    top: -0.6875em;
    left: 1.875em;
    transform: rotate(-45deg);
    transform-origin: 0 3.75em;
    border-radius: 0 7.5em 7.5em 0;
  }

  .swal2-confirm{
    order: 9999 !important;
  }