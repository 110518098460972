@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .gradient-0 {
    @apply bg-gradient-to-br from-[#5B8DEF] to-[#0063F7];
  }

  .gradient-1 {
    @apply bg-gradient-to-br from-[#FF8800] to-[#E63535];
  }

  .gradient-2 {
    @apply bg-gradient-to-br from-[#3E7BFA] to-[#6600CC];
  }

  .gradient-3 {
    @apply bg-gradient-to-br from-[#00CFDE] to-[#05A660];
  }

  .gradient-4 {
    @apply bg-gradient-to-b from-[#0F0C29] via-[#302B63] to-[#24243E];
  }

  .gradient-5 {
    @apply bg-gradient-to-br from-[#FDDD48] to-[#00B7C4];
  }

  .gradient-6 {
    @apply bg-gradient-to-br from-[#FF3B3B] to-[#6600CC];
  }

  .gradient-7 {
    @apply bg-gradient-to-br from-[#73DFE7] to-[#0063F7];
  }

  .gradient-8 {
    @apply bg-gradient-to-br from-[#AC5DD9] to-[#004FC4];
  }

  .gradient-9 {
    @apply bg-gradient-to-br from-[#5B8DEF] to-[#0063F7];
  }
}

@property --var1 {
  syntax: "<color>";
  inherits: true;
  initial-value: rgba(255, 255, 255, 0);
}

@keyframes fadeIn {
  0% {
    --var1: rgba(255, 255, 255, 0);
  }

  100% {
    --var1: #aaa;
  }
}

@keyframes fadeOut {
  0% {
    --var1: #aaa;
  }

  100% {
    --var1: rgba(255, 255, 255, 0);
  }
}

/* .sidebar::-webkit-scrollbar {
  width: 0 !important;
}
.sidebar {
  overflow: -moz-scrollbars-none;
} */
.sidebar {
  /* -ms-overflow-style: none; */
  width: 100%;
  overflow: auto;
  animation: fadeOut .5s ease-in-out forwards;
}

.sidebar:hover {
  animation: fadeIn .5s ease-in-out forwards;
}

.sidebar::-webkit-scrollbar {
  background: rgba(255, 255, 255, 0);
  width: 6px;
  height: 6px;
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: var(--var1);
  border-radius: 4px;
}

.be-white .relative .be-white {
  background-color: #FFF;
}

.be-white .relative .shadow-lv3 {
  width: 11rem;
}

/* CHECKBOX ROUNDED */
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #3366ff;
  border-color: #3366ff;
}

.round input[type="checkbox"] + label {
  background-color: #ffffff00;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.grecaptcha-badge {
  z-index: 11;
}

/* Input Range */

.__range {
  margin: 30px 0 20px 0;
  -webkit-appearance: none;
  background-color: #e2e8f0;
  height: 3px;
  width: 100%;
  margin: 10px auto;
}
.__range:focus {
  outline: none;
}
.__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #3366ff;
  border-radius: 50%;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* line clamp */
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

/* MAP */

#map_sual .gmnoprint {
  display: none !important;
}

#map_sual .gm-fullscreen-control {
  display: none !important;
}

#map_sual .gm-style-iw-t {
  bottom: 50px !important;
}
#map_sual .gm-style .gm-style-iw-c {
  padding: 0;
}

#map_sual .gm-style-iw-d {
  overflow: auto !important;
}

#map_sual .gm-ui-hover-effect {
  display: none !important;
}

#map_sual .gm-style .gm-style-iw-tc::after {
  display: none;
}

/* input type number */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

/* datepicker */
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  left: -20px !important;
}

.react-datepicker__navigation .react-datepicker__navigation--next {
  right: 2px !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 2px !important;
}

/* thumb carousel */

.carousel .slide .thumbnail, .carousel .slide.previous .thumbnail{
  border-radius: 12px !important;
  width: 100% !important;
  height: 246px !important;
  object-fit: contain !important;
  background: #eee;
}

.PhotoView-Slider__Backdrop {
  background: #000000ad !important;
  backdrop-filter: blur(5px) !important;
}

.carousel .thumb {
  transition: border .55s ease-in;
  display: inline-block;
  margin-right: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  border: 0 !important;
  padding: 0 !important;
}

.carousel .thumb .thumbnail{
  border-radius: 6px !important;
  border: 1.5px solid #3366ff00 !important;
  outline: 1px solid #3366ff00 !important;
  outline-offset: -1px;
  opacity: 0.6;
  width: 100% !important;
  height: 40px !important;
  object-fit: cover !important;
}

.carousel .thumb.selected .thumbnail, .carousel .thumb:hover .thumbnail{
  border: 1.5px solid #3366FF !important;
  outline: 1px solid #3366FF !important;
  outline-offset: -2px;
  opacity: 1;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 0 !important;
  width: 100px;
}

.carousel .control-next.control-arrow:before{
  border-left: 8px solid #A0AEC0 !important;
  display: none;
}

.carousel .control-prev.control-arrow:before{
  border-right: 8px solid #A0AEC0 !important;
  display: none;
}

.carousel .thumbs-wrapper {
  margin-left: 5px !important;
  margin-right: 5px !important;
  overflow: auto;
}

.carousel .thumbs {
  text-align: center !important;
}
