/* datepicker */
.react-datepicker {
  font-family: 'Inter' !important;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000000;
  border: 1px solid #eceaea !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25) !important;
}

.react-datepicker__month-container {
  padding-top: 15px;
}

.with-time .react-datepicker__navigation {
  margin-top: 12px !important;
}

.react-datepicker__navigation-icon {
  top: 1px !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  left: -20px !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #eceaea !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #eceaea !important;
}

.react-datepicker__navigation .react-datepicker__navigation--next {
  right: 2px !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px !important;
  color: #000000;
}

.react-datepicker__month-year-read-view--down-arrow {
  top: 2px !important;
  right: -20px !important;
}

.with-time .react-datepicker{
  width: 328px !important;
}

.with-time .react-datepicker__day-names {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-weight: 600;
}

.react-datepicker__day-names {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  font-weight: 600;
}

.react-datepicker__header {
  text-align: center;
  background-color: #ffffff !important;
  border-bottom: 1px solid #f1f1f1 !important;
  padding: 0 0 5px 0 !important;
  position: relative;
}

.react-datepicker__header--time {
  padding: 12px 8px 8px 8px !important;
}

.react-datepicker__day:hover {
  border-radius: 9999px !important;
  background-color: #D6E4FF !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 9999px !important;
  background-color: #3366FF !important;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  border-radius: 9999px !important;
  background-color: #3366FF !important;
  color: #fff !important;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  border-radius: 9999px !important;
  background-color: #3366FF !important;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #3366FF !important;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: transparent !important;
}

.react-datepicker__time-list {
  width: 85px;
  overflow-x: hidden;
  margin: 0px auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
  overflow: -moz-scrollbars-none !important;
  -ms-overflow-style: none;
}

.react-datepicker__time-list::-webkit-scrollbar {
  width: 0 !important;
}

.react-datepicker__time-container {
  border-left: 1px solid #f1f1f1 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #3366FF !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: #D6E4FF !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #3366FF !important;
}

.with-time .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  margin: 10px 0 !important;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  margin: 12px 0 !important;
}

.with-time .react-datepicker__year-dropdown-container--scroll, .with-time .react-datepicker__month-dropdown-container--scroll {
  display: inline-block;
  margin: 0px 30px !important;
}

.react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll {
  display: inline-block;
  margin: 0px 30px !important;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  background-color: #ffffff !important;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 999 !important;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #fefefe !important;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25) !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #000000 !important;
  border-style: solid;
  border-width: 2px 2px 0 0 !important;
}